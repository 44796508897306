export const ClientEvents = {
  MessageNew: 'message.new',
  MessageRead: 'message.read',
  MessageDeleted: 'message.deleted',
  MessageUpdated: 'message.updated',
  MessageUnpinned: 'message.unpinned',
  MessagePinned: 'message.pinned',
  TypingStart: 'typing.start',
  TypingStop: 'typing.stop',
  ReactionDeleted: 'reaction.deleted',
  ReactionNew: 'reaction.new',
  MemberRemoved: 'member.removed',
  MemberAdded: 'member.added',
  MemberPromoted: 'member.promoted',
  MemberDemoted: 'member.demoted',
  MemberBanned: 'member.banned',
  MemberUnBanned: 'member.unbanned',
  MemberBlocked: 'member.blocked',
  MemberUnblocked: 'member.unblocked',
  MemberJoined: 'member.joined', // only use for public channel when user join from link
  Signal: 'signal',
  Notification: {
    // AddedToChannel: 'notification.added_to_channel',
    InviteAccepted: 'notification.invite_accepted',
    InviteRejected: 'notification.invite_rejected',
  },
  ChannelDeleted: 'channel.deleted',
  ChannelUpdated: 'channel.updated',
  ChannelTruncate: 'channel.truncate',
  ChannelCreated: 'channel.created',
  ConnectionChanged: 'connection.changed',
  UserWatchingStart: 'user.watching.start',
  UserWatchingStop: 'user.watching.stop',
};
