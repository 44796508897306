import * as React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import getColorName from '../utils/getColorName';
import { useState, useEffect, useMemo } from 'react';
import capitalizeFirstLetter from '../utils/commons';
import { useTheme } from '@emotion/react';
import { ChatType, OnlineStatusUser } from '../constants/commons-const';
import { useSelector } from 'react-redux';
import useOnlineStatus from '../hooks/useOnlineStatus';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    '& .MuiAvatar-root': {
      // fontSize: '1rem',
      // fontWeight: '400',
    },
  },
}));

const StyledBadgeOnline = styled(Badge)(({ theme, status }) => ({
  '& .MuiBadge-badge': {
    backgroundColor:
      status === OnlineStatusUser.ONLINE ? '#44b700' : status === OnlineStatusUser.OFFLINE ? '#ddd' : 'transparent',
    color:
      status === OnlineStatusUser.ONLINE ? '#44b700' : status === OnlineStatusUser.OFFLINE ? '#ddd' : 'transparent',
    boxShadow: status !== OnlineStatusUser.UNKNOWN ? `0 0 0 2px ${theme.palette.background.paper}` : 'none',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.paper}`,
}));

export default function ChannelAvatar({ channel, width, height }) {
  const theme = useTheme();
  const { user_id } = useSelector(state => state.auth);
  const { all_members } = useSelector(state => state.member);
  const [directMember, setDirectMember] = useState({ name: '', avatar: null });
  const [groupMemberFirst, setGroupMemberFirst] = useState({ name: '', avatar: null });
  const [groupMemberSecond, setGroupMemberSecond] = useState({ name: '', avatar: null });

  const isDirect = channel?.type === ChatType.MESSAGING;
  const channelAvatar = channel?.data.image;

  const members = useMemo(() => (isDirect ? Object.values(channel.state.members) : []), [channel, isDirect]);

  const otherMember = useMemo(() => members.find(member => member.user_id !== user_id), [members, user_id]);

  const otherMemberId = otherMember?.user_id;
  const onlineStatus = useOnlineStatus(isDirect ? otherMemberId : '');

  useEffect(() => {
    if (channel && all_members.length) {
      const members = Object.values(channel.state.members);
      if (isDirect) {
        const otherMember = members.find(member => member.user_id !== user_id);
        if (otherMember) {
          const userInfo = all_members.find(user => user.id === otherMember.user.id);
          setDirectMember({
            name: userInfo ? userInfo.name : otherMember.user.id,
            avatar: userInfo ? userInfo.avatar : null,
          });
        } else {
          setDirectMember({ name: '', avatar: null });
        }
      } else {
        const firstMember = members ? members[0] : null;

        if (firstMember) {
          const memberInfoFirst = all_members.find(user => user.id === firstMember.user.id);

          setGroupMemberFirst({
            name: memberInfoFirst ? memberInfoFirst.name : firstMember.user.id,
            avatar: memberInfoFirst ? memberInfoFirst.avatar : null,
          });
        } else {
          setGroupMemberFirst({ name: '', avatar: null });
        }

        const secondMember = members ? members[1] : null;

        if (secondMember) {
          const memberInfoSecond = all_members.find(user => user.id === secondMember.user.id);

          setGroupMemberSecond({
            name: memberInfoSecond ? memberInfoSecond.name : secondMember.user.id,
            avatar: memberInfoSecond ? memberInfoSecond.avatar : null,
          });
        } else {
          setGroupMemberSecond({ name: '', avatar: null });
        }
      }
    }
  }, [channel, isDirect, user_id, all_members]);

  const getSizeSmallAvatar = size => {
    return (62.5 * size) / 100;
  };

  const getFontSizeAvatar = size => {
    return `${size / 2}px`;
  };

  const getSizeBadgeOnline = size => {
    return `${size / 5}px`;
  };

  if (!channel) return null;

  return (
    <Stack direction="row" spacing={2} sx={{ position: 'relative' }}>
      {isDirect ? (
        <StyledBadgeOnline
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          sx={{
            '& .MuiBadge-badge': {
              minWidth: getSizeBadgeOnline(width),
              width: getSizeBadgeOnline(width),
              height: getSizeBadgeOnline(width),
            },
          }}
          status={onlineStatus}
        >
          <Avatar
            alt={capitalizeFirstLetter(directMember.name)}
            src={directMember.avatar ? directMember.avatar : directMember.name}
            sx={{
              background: directMember.avatar ? theme.palette.background.paper : getColorName(directMember.name),
              color: '#fff',
              width: width,
              height: height,
              border: `1px solid ${theme.palette.background.paper}`,
              fontSize: getFontSizeAvatar(width),
            }}
          />
        </StyledBadgeOnline>
      ) : channelAvatar ? (
        <Avatar
          src={channelAvatar}
          sx={{
            background: theme.palette.background.paper,
            width: width,
            height: height,
            border: `1px solid ${theme.palette.background.paper}`,
          }}
        />
      ) : (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <SmallAvatar
              alt={capitalizeFirstLetter(groupMemberFirst.name)}
              src={groupMemberFirst.avatar ? groupMemberFirst.avatar : groupMemberFirst.name}
              sx={{
                background: groupMemberFirst.avatar
                  ? theme.palette.background.paper
                  : getColorName(groupMemberFirst.name),
                color: '#fff',
                fontWeight: '400',
                fontSize: getFontSizeAvatar(getSizeSmallAvatar(width)),
                width: getSizeSmallAvatar(width),
                height: getSizeSmallAvatar(height),
              }}
            />
          }
        >
          <Avatar
            alt={capitalizeFirstLetter(groupMemberSecond.name)}
            src={groupMemberSecond.avatar ? groupMemberSecond.avatar : groupMemberSecond.name}
            sx={{
              background: groupMemberSecond.avatar
                ? theme.palette.background.paper
                : getColorName(groupMemberSecond.name),
              color: '#fff',
              width: width,
              height: height,
              border: `1px solid ${theme.palette.background.paper}`,
              fontSize: getFontSizeAvatar(width),
            }}
          />
        </StyledBadge>
      )}
    </Stack>
  );
}
