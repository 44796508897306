import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import getColorName from '../utils/getColorName';
import capitalizeFirstLetter from '../utils/commons';
import { useTheme } from '@emotion/react';
import { Badge, styled } from '@mui/material';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { OnlineStatusUser } from '../constants/commons-const';

const StyledBadgeOnline = styled(Badge)(({ theme, status }) => ({
  '& .MuiBadge-badge': {
    backgroundColor:
      status === OnlineStatusUser.ONLINE ? '#44b700' : status === OnlineStatusUser.OFFLINE ? '#ddd' : 'transparent',
    color:
      status === OnlineStatusUser.ONLINE ? '#44b700' : status === OnlineStatusUser.OFFLINE ? '#ddd' : 'transparent',
    boxShadow: status !== OnlineStatusUser.UNKNOWN ? `0 0 0 2px ${theme.palette.background.paper}` : 'none',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));

export default function MemberAvatar({ member, width, height }) {
  const theme = useTheme();
  const onlineStatus = useOnlineStatus(member?.id || '');

  const getFontSizeAvatar = size => {
    return `${size / 2}px`;
  };

  const getSizeBadgeOnline = size => {
    return `${size / 5}px`;
  };

  if (!member) {
    return null;
  }

  return (
    <StyledBadgeOnline
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      sx={{
        '& .MuiBadge-badge': {
          minWidth: getSizeBadgeOnline(width),
          width: getSizeBadgeOnline(width),
          height: getSizeBadgeOnline(width),
        },
      }}
      status={onlineStatus}
    >
      <Avatar
        alt={capitalizeFirstLetter(member.name)}
        src={member.avatar ? member.avatar : member.name}
        sx={{
          background: member.avatar ? theme.palette.background.paper : getColorName(member.name),
          color: '#fff',
          width: width,
          height: height,
          border: `1px solid ${theme.palette.background.paper}`,
          fontSize: getFontSizeAvatar(width),
        }}
      />
    </StyledBadgeOnline>
  );
}
